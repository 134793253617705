<template>
  <b-card ref="element">
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <b-form
        class="mt-2"
        @submit.prevent
      >
        <h4>Add New Session</h4>

        <b-form-row class="mt-3">
          <b-col
            v-if="eventActionType === null"
            md="6"
          >
            <custom-vue-select
              id="prayer-action-type"
              label="Prayer Action Type"
              :value-reducer="option=>option.value"
              rules="required"
              text-field="text"
              :options="actionTypeOptions"
              :selected.sync="sessionData.action_type"
            />
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Topic"
              rules="required|min:3|max:200"
            >
              <b-form-group
                label="Topic"
                label-for="topic"
                class="mb-2"
              >
                <b-form-input
                  id="topic"
                  v-model="sessionData.topic"
                  placeholder="Topic"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Language"
            >
              <b-form-group
                label="Language"
                label-for="notes"
              >
                <b-form-select
                  v-model="sessionData.session_languages"
                  :options="languages"
                  value-field="id"
                  :state="getValidationState(validationContext)"
                  text-field="name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="From"
              rules="required"
              :detect-input="false"
            >
              <b-form-group
                label="From"
              >
                <flat-pickr
                  v-model="sessionData.session_start"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate: selectedEventDates.start_time,maxDate:selectedEventDates.end_time}"
                  @input="validationContext.validate(sessionData.session_start)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="To"
              rules="required"
              :detect-input="false"
            >
              <b-form-group
                label="To"
                class="mb-2"
              >
                <flat-pickr
                  v-model="sessionData.session_end"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    minDate: $moment(sessionData.session_start).add({minutes:1}).format('YYYY-MM-DD HH:mm'),
                    maxDate: selectedEventDates.end_time,
                  }"
                  @input="validationContext.validate(sessionData.session_end)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Capacity"
              :rules="`${isActionTypeBooking?'required':''}|min_value:1`"
            >
              <b-form-group
                label="Capacity"
                label-for="capacity"
                class="mb-2"
              >
                <b-form-input
                  id="capacity"
                  v-model="sessionData.session_max_attendance"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Max Family Members"
              :rules="`${isActionTypeBooking?'required':''}|min_value:1|max_value:${sessionData.session_max_attendance}`"
            >
              <b-form-group
                label="Max Family Members"
                label-for="max-family-members"
                class="mb-2"
              >
                <b-form-input
                  id="max-family-members"
                  v-model="sessionData.session_max_family_members"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Target Gender"
              :rules="isActionTypeBooking?'required':''"
            >
              <b-form-group
                label="Target Gender"
                label-for="gender"
              >
                <b-form-select
                  id="gender"
                  v-model="sessionData.session_target_gender"
                  :options="genderOptions"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Link"
              rules="url"
            >
              <b-form-group
                label="Link"
                label-for="session-url"
                class="mb-2"
              >
                <b-form-input
                  id="session-url"
                  v-model="sessionData.session_link"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-form-row>

        <b-form-row class="border p-1 my-1">
          <b-col cols="12">
            <h3 class="mb-2">
              Custom Section
            </h3>
          </b-col>

          <b-col md="6">
            <text-input
              id="custom-title"
              name="Title"
              :text.sync="sessionData.custom_section_key"
            />
          </b-col>

          <b-col cols="12">
            <text-area-input
              id="custom-location"
              name="Description"
              rules="max:800"
              :text.sync="sessionData.custom_section_value"
            />
          </b-col>

        </b-form-row>

        <b-col
          cols="12"
          class="border p-2 mb-1"
        >
          <h4 class="mb-1">
            Session Address
          </h4>
          <location-inputs :details="sessionData.address" />
          <div class="text-center text-warning">
            No need to add address if the session is in the same organization premises
          </div>
        </b-col>

        <validation-provider
          #default="validationContext"
          name="General Notes"
          rules=""
        >
          <b-form-group
            label="General Notes"
            label-for="notes"
            class="mb-2"
          >
            <b-form-textarea
              id="notes"
              v-model="sessionData.session_description"
              placeholder="General notes for this session"
              rows="3"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-form-group
          label="Status"
          label-for="status"
        >
          <b-radio-group
            id="status"
            v-model="sessionData.session_status"
            :options="[
              {text:'Active',value:'active'},
              {text:'Inactive',value:'inactive'}
            ]"
          />
        </b-form-group>

        <fieldset :disabled="['Mark Attending','mark_attending'].includes(sessionData.action_type)">
          <b-form-group
            label="Auto Approval"
            label-cols-md="2"
            label-for="approval"
            class="mb-2"
          >
            <b-form-checkbox
              id="approval"
              v-model="sessionData.session_approval"
              :value="1"
              :unchecked-value="0"
            />
          </b-form-group>

          <b-form-group
            label="Vaccination Required"
            label-cols-md="2"
            label-for="is-vaccinated"
            class="mb-2"
          >
            <b-form-checkbox
              id="is-vaccinated"
              v-model="sessionData.required_vaccination"
              :value="1"
              :unchecked-value="0"
            />
          </b-form-group>
        </fieldset>

        <submit-button
          :handle-submit="handleSubmit"
          :submit="createSession"
        />
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css'
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import scroll from '@/common/compositions/common/scrollToElement'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import CustomVueSelect from '../../common/FormInputs/CustomVueSelect.vue'
import LocationInputs from '../../common/FormInputs/LocationInputs.vue'
import TextInput from '../../common/FormInputs/TextInput.vue'
import TextAreaInput from '../../common/FormInputs/TextAreaInput.vue'

export default {
  name: 'AddSession',
  components: {
    flatPickr,
    SubmitButton,
    CustomVueSelect,
    LocationInputs,
    TextInput,
    TextAreaInput,
  },
  props: {
    selectedEventId: { type: Number, default: null },
    selectedEventDates: { type: Object, default: null },
    eventActionType: { type: String, default: null },
  },
  data() {
    return {
      sessionData: {
        activity_id: null,
        session_start: null,
        session_end: null,
        session_languages: null,
        session_max_attendance: null,
        session_status: 'inactive',
        session_target_gender: null,
        session_approval: 1,
        session_provider: null,
        session_max_family_members: null,
        required_vaccination: 0,
        session_description: null,
        address: {},
        action_type: this.eventActionType,
      },
      languages: [
        { id: 'Arabic', name: 'Arabic' },
        { id: 'English', name: 'English' },
        { id: 'French', name: 'French' },
        { id: 'Spanish', name: 'Spanish' },
        { id: 'Urdu', name: 'Urdu' },
      ],
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Both', value: 'both' },
      ],
    }
  },
  computed: {
    actionTypeOptions() {
      return [{ text: 'Booking', value: 'booked' }, { text: 'Mark Attending', value: 'mark_attending' }]
    },
    isActionTypeBooking() {
      return this.sessionData.action_type === this.actionTypeOptions[0].value
    },
    endDate: {
      get() {
        return this.sessionData.session_end
      },
    },
  },
  mounted() {
    this.scrollToElement()
  },
  setup() {
    const { element, scrollToElement } = scroll()
    const {
      getValidationState,
    } = formValidation()
    const { successfulOperationAlert } = handleAlerts()

    return {
      successfulOperationAlert,
      element,
      scrollToElement,
      getValidationState,
    }
  },
  methods: {
    createSession() {
      this.setCreatedEventID()
      return this.$activities.post('internalops/prayer-activities/addsessions', {
        ...this.sessionData,
        ...this.sessionData.address,
        action_type: ['Booking', 'booked'].includes(this.sessionData.action_type) ? 'booked' : 'mark_attending',
      }).then(() => {
        this.successfulOperationAlert('Session is added successfully')
        this.updateSessionsTable()
      })
    },
    setCreatedEventID() {
      this.sessionData.activity_id = this.selectedEventId
    },
    updateSessionsTable() {
      this.$emit('addSession')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
