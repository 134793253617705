import axiosInstances from '@/libs/axios-instances'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default function session() {
  const { successfulOperationAlert, failedOperationAlert } = handleAlerts()
  const formatSessionData = sessionData => ({
    activity_id: sessionData.activity_id,
    session_start: sessionData.session_start,
    session_end: sessionData.session_end,
    session_languages: sessionData.session_languages,
    session_max_attendance: sessionData.session_max_attendance,
    session_status: sessionData.session_status,
    session_target_gender: sessionData.session_target_gender,
    session_approval: sessionData.session_approval,
    topic: sessionData.topic,
    session_link: sessionData.session_link,
    session_max_family_members: sessionData.session_max_family_members,
    required_vaccination: sessionData.required_vaccination,
    session_description: sessionData.session_description,
    action_type: ['Booking', 'booked'].includes(sessionData.action_type) ? 'booked' : 'mark_attending',
    custom_section_key: sessionData.custom_section_key,
    custom_section_value: sessionData.custom_section_value,
    ...sessionData.address,
  })

  const duplicateSessionPromise = sessionData => new Promise((resolve, reject) => {
    const formattedSessionData = formatSessionData(sessionData)
    axiosInstances.activities.post('internalops/prayer-activities/addsessions', formattedSessionData).then(() => {
      successfulOperationAlert('Session is duplicated successfully')
      resolve()
    }).catch(() => {
      failedOperationAlert('Failed to duplicate session')
      reject()
    })
  })

  return { duplicateSessionPromise }
}
