<template>
  <b-card ref="element">
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <b-form
        class="mt-2"
        @submit.prevent
      >
        <h4>
          Edit Session
        </h4>
        <b-form-row class="mt-3">
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Topic"
              rules="required|min:3|max:200"
            >
              <b-form-group
                label="Topic"
                label-for="topic"
                class="mb-2"
              >
                <b-form-input
                  id="topic"
                  v-model="selectedSessionToEdit.topic"
                  placeholder="Topic"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Language"
            >
              <b-form-group
                label="Language"
                label-for="notes"
              >
                <b-form-select
                  v-model="selectedSessionToEdit.session_languages"
                  :options="languages"
                  value-field="id"
                  text-field="name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="From"
              rules="required"
            >
              <b-form-group
                label="From"
              >
                <flat-pickr
                  v-model="selectedSessionToEdit.session_start"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i:s',
                    minDate: selectedEventDates.start_time,
                    maxDate:selectedEventDates.end_time}"
                  @input="validationContext.validate(selectedSessionToEdit.session_start)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="To"
              rules="required"
            >
              <b-form-group
                label="To"
                class="mb-2"
              >
                <flat-pickr
                  v-model="selectedSessionToEdit.session_end"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i:s',
                    minDate:$moment(selectedSessionToEdit.session_start).add({minutes:1}).format('YYYY-MM-DD HH:mm')
                  }"
                  @input="validationContext.validate(selectedSessionToEdit.session_end)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Capacity"
              :rules="`${isActionTypeBooking?'required':''}|min_value:1`"
            >
              <b-form-group
                label="Capacity"
                label-for="capacity"
                class="mb-2"
              >
                <b-form-input
                  id="capacity"
                  v-model="selectedSessionToEdit.session_max_attendance"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Max Family Members"
              :rules="`${isActionTypeBooking?'required':''}|min_value:1|max_value:${selectedSessionToEdit.session_max_attendance}`"
            >
              <b-form-group
                label="Max Family Members"
                label-for="max-family-members"
                class="mb-2"
              >
                <b-form-input
                  id="max-family-members"
                  v-model="selectedSessionToEdit.session_max_family_members"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Target Gender"
              :rules="isActionTypeBooking?'required':''"
            >
              <b-form-group
                label="Target Gender"
                label-for="gender"
              >
                <b-form-select
                  id="gender"
                  v-model="selectedSessionToEdit.session_target_gender"
                  :options="genderOptions"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Link"
              rules="url"
            >
              <b-form-group
                label="Link"
                label-for="session-url"
                class="mb-2"
              >
                <b-form-input
                  id="session-url"
                  v-model="selectedSessionToEdit.session_link"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <b-form-row class="border p-1 my-1">
              <b-col cols="12">
                <h3 class="mb-2">
                  Custom Section
                </h3>
              </b-col>
              <b-col md="6">
                <text-input
                  id="custom-title"
                  name="Title"
                  :text.sync="selectedSessionToEdit.custom_section_key"
                />
              </b-col>
              <b-col cols="12">
                <text-area-input
                  id="custom-location"
                  name="Description"
                  rules="max:800"
                  :text.sync="selectedSessionToEdit.custom_section_value"
                />
              </b-col>
            </b-form-row>
          </b-col>

          <b-col
            cols="12"
            class="border p-2 mb-1"
          >
            <h4 class="mb-1">
              Session Address
            </h4>
            <location-inputs :details="selectedSessionToEdit.address" />
            <div class="text-center text-warning">
              No need to add address if the session is in the same organization premises
            </div>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="General Notes"
              rules=""
            >
              <b-form-group
                label="General Notes"
                label-for="notes"
                class="mb-2"
              >
                <b-form-textarea
                  id="notes"
                  v-model="selectedSessionToEdit.session_description"
                  placeholder="General notes for this session"
                  :state="getValidationState(validationContext)"
                  rows="3"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>

        <b-form-group
          label="Status"
          label-for="status"
        >
          <b-radio-group
            id="status"
            v-model="selectedSessionToEdit.session_status"
            :options="[
              {text:'Active',value:'active'},
              {text:'Inactive',value:'inactive'}
            ]"
          />
        </b-form-group>

        <fieldset :disabled="['Mark Attending','mark_attending'].includes(selectedSessionToEdit.action_type)">
          <b-form-group
            label="Auto Approval"
            label-cols-md="2"
            label-for="approval"
            class="mb-2"
          >
            <b-form-checkbox
              id="approval"
              v-model="selectedSessionToEdit.session_approval"
              :value="1"
              :unchecked-value="0"
            />
          </b-form-group>
          <b-form-group
            label="Vaccination Required"
            label-cols-md="2"
            label-for="is-vaccinated"
            class="mb-2"
          >
            <b-form-checkbox
              id="is-vaccinated"
              v-model="selectedSessionToEdit.required_vaccination"
              :value="1"
              :unchecked-value="0"
            />
          </b-form-group>
        </fieldset>

        <submit-button
          :handle-submit="handleSubmit"
          :submit="editSession"
        />
        <back />
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import scroll from '@/common/compositions/common/scrollToElement'
import Back from '@/common/components/common/FormInputs/Back.vue'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import TextInput from '../../common/FormInputs/TextInput.vue'
import TextAreaInput from '../../common/FormInputs/TextAreaInput.vue'
import LocationInputs from '../../common/FormInputs/LocationInputs.vue'

export default {
  name: 'EditSession',
  components: {
    flatPickr,
    Back,
    SubmitButton,
    TextInput,
    TextAreaInput,
    LocationInputs,
  },
  props: {
    selectedSessionToEdit: { type: Object, default: () => null },
    selectedEventDates: { type: Object, default: () => null },
    entityId: { type: Number, default: null },
  },
  data() {
    return {
      languages: [
        { id: 'Arabic', name: 'Arabic' },
        { id: 'English', name: 'English' },
        { id: 'French', name: 'French' },
        { id: 'Spanish', name: 'Spanish' },
        { id: 'Urdu', name: 'Urdu' },
      ],
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Both', value: 'both' },
      ],
    }
  },
  computed: {
    sessionProxy() {
      return {
        session_max_family_members: this.selectedSessionToEdit.session_max_family_members,
        session_start: this.selectedSessionToEdit.session_start,
        session_end: this.selectedSessionToEdit.session_end,
        session_languages: this.selectedSessionToEdit.session_languages,
        session_max_attendance: this.selectedSessionToEdit.session_max_attendance,
        session_status: this.selectedSessionToEdit.session_status,
        session_target_gender: this.selectedSessionToEdit.session_target_gender,
        session_approval: this.selectedSessionToEdit.session_approval,
        topic: this.selectedSessionToEdit.topic,
        required_vaccination: this.selectedSessionToEdit.required_vaccination,
        session_description: this.selectedSessionToEdit.session_description,
        session_link: this.selectedSessionToEdit.session_link,
        action_type: ['Booking', 'booked'].includes(this.selectedSessionToEdit.action_type) ? 'booked' : 'mark_attending',
        custom_section_key: this.selectedSessionToEdit.custom_section_key,
        custom_section_value: this.selectedSessionToEdit.custom_section_value,
        ...this.selectedSessionToEdit.address,
      }
    },
    actionTypeOptions() {
      return [{ text: 'Booking', value: 'booked' }, { text: 'Mark Attending', value: 'mark_attending' }]
    },
    isActionTypeBooking() {
      return this.selectedSessionToEdit.action_type === this.actionTypeOptions[0].value
    },
  },
  mounted() {
    this.scrollToElementWhenDOMReady()
  },
  setup() {
    const { element, scrollToElement } = scroll()
    const { successfulOperationAlert } = handleAlerts()
    const {
      getValidationState,
    } = formValidation()
    return {
      successfulOperationAlert,
      element,
      scrollToElement,
      getValidationState,
    }
  },
  methods: {
    editSession() {
      return this.$activities.post(`internalops/sessions_update/${this.selectedSessionToEdit.id}?_method=PUT`, this.sessionProxy, {
        headers: {
          entityId: this.entityId,
        },
      }).then(() => {
        this.successfulOperationAlert('Session is edited successfully')
        this.$emit('editSession')
      })
    },
    scrollToElementWhenDOMReady() {
      setTimeout(() => {
        this.scrollToElement()
      })
    },
  },
}
</script>
<style lang="">

</style>
