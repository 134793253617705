import axiosInstances from '@/libs/axios-instances'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default function sessionStatus() {
  const { successfulOperationAlert, failedOperationAlert } = handleAlerts()
  const updateSessionStatus = id => {
    axiosInstances.activities.post(`internalops/session-toggle-status/${id}?_method=PUT`).then(() => {
      successfulOperationAlert('Status is updated Successfully')
    }).catch(() => {
      failedOperationAlert('Failed to update status')
    })
  }

  return { updateSessionStatus }
}
