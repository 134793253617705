import { ref } from '@vue/composition-api'

export default function scroll() {
  const element = ref(null)
  const scrollToElement = () => {
    if (element.value) {
      element.value.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return {
    element,
    scrollToElement,
  }
}
